<template>
  <div v-if="accountData">
    <b-card>
      <!-- License Image -->
      <b-media
        v-if="accountData.role.includes('evaluator')"
        no-body
      >
        <b-media-aside>
          <b-link>
            <!-- Use a v-if to check if accountData.license exists -->
            <b-img
              v-if="accountData && accountData.license"
              ref="licenseImagePreview"
              rounded
              :src="accountData.license"
              height="80"
            />
            <!-- If accountData.license doesn't exist or is null, show a placeholder image -->
            <b-img
              v-else
              ref="licenseImagePreview"
              rounded
              src="/licence-placeholder.png"
              height="80"
            />
          </b-link>
        </b-media-aside>

        <b-media-body class="mt-75 ml-75">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            size="sm"
            class="mb-75 mr-75"
            @click="$refs.licenseImageInput.$el.click()"
          >
            اختر صورة الترخيص
          </b-button>
          <b-form-file
            ref="licenseImageInput"
            v-model="licenseFile"
            name="image"
            accept="image/*"
            :hidden="true"
            plain
            @input="licenseImageRenderer"
          />
          <!-- reset -->
          <b-button
            v-if="licenseFile"
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            size="sm"
            class="mb-75 mr-75"
            @click="updateLicenseImage"
          >
            تحميل
          </b-button>
          <!--/ reset -->
          <b-card-text>
            الصيغة المسموح بها: JPG، GIF، أو PNG. الحد الأقصى 2 ميجابايت
          </b-card-text>
        </b-media-body>
      </b-media>
      <!--/ License Image -->

      <!-- Signature Image -->
      <b-media no-body>
        <b-media-aside>
          <b-link>
            <!-- Use a v-if to check if accountData.signature exists -->
            <b-img
              v-if="accountData && accountData.signature"
              ref="signatureImagePreview"
              rounded
              :src="accountData.signature"
              height="80"
            />
            <!-- If accountData.signature doesn't exist or is null, show a placeholder image -->
            <b-img
              v-else
              ref="signatureImagePreview"
              rounded
              src="/signature-placeholder.png"
              height="80"
            />
          </b-link>
        </b-media-aside>

        <b-media-body class="mt-75 ml-75">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            size="sm"
            class="mb-75 mr-75"
            @click="$refs.signatureImageInput.$el.click()"
          >
            اختر صورة التوقيع
          </b-button>
          <b-form-file
            ref="signatureImageInput"
            v-model="signatureFile"
            name="image"
            accept="image/*"
            :hidden="true"
            plain
            @input="signatureImageRenderer"
          />
          <!-- reset -->
          <b-button
            v-if="signatureFile"
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            size="sm"
            class="mb-75 mr-75"
            @click="updateSignatureImage"
          >
            تحميل
          </b-button>
          <!--/ reset -->
          <b-card-text>
            الصيغة المسموح بها: JPG، GIF، أو PNG. الحد الأقصى 2 ميجابايت
          </b-card-text>
        </b-media-body>
      </b-media>
    <!--/ Signature Image -->
    </b-card>
    <b-card>
      <h3>معلومات شخصية</h3>
      <b-form class="mt-2">
        <!-- Form -->
        <b-row class="mt-2">
          <b-col
            cols="12"
            md="6"
            lg="6"
          >
            <!-- Field: الاسم -->
            <b-form-group
              label="الاسم"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="accountData.name"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="6"
          >
            <!-- Field: رقم الهاتف -->
            <b-form-group
              label="رقم الهاتف"
              label-for="mobile"
            >
              <b-form-input
                id="mobile"
                v-model="accountData.mobile"
                type="tel"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            lg="6"
          >
            <!-- Field: البريد الإلكتروني (readonly) -->
            <b-form-group
              label="البريد الإلكتروني"
              label-for="email"
            >
              <b-form-input
                id="email"
                v-model="accountData.email"
                readonly
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-2 mr-1"
              @click="updateAccount"
            >
              حفظ التغييرات
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
    <!-- Reset Password Card -->
    <b-card>
      <h3>إعادة تعيين كلمة المرور</h3>
      <b-form class="mt-2">
        <!-- Old Password Field -->
        <b-form-group
          label="كلمة المرور القديمة"
          label-for="oldPassword"
        >
          <b-form-input
            id="oldPassword"
            v-model="resetPasswordData.oldPassword"
            type="password"
          />
        </b-form-group>

        <!-- New Password Field -->
        <b-form-group
          label="كلمة المرور الجديدة"
          label-for="newPassword"
        >
          <b-form-input
            id="newPassword"
            v-model="resetPasswordData.newPassword"
            type="password"
          />
        </b-form-group>

        <!-- Confirm New Password Field -->
        <b-form-group
          label="تأكيد كلمة المرور الجديدة"
          label-for="confirmNewPassword"
        >
          <b-form-input
            id="confirmNewPassword"
            v-model="resetPasswordData.confirmNewPassword"
            type="password"
          />
        </b-form-group>

        <b-row>
          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-2"
              @click="resetPassword"
            >
              إعادة تعيين كلمة المرور
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
  </div>
</template>

<script>
import {
  BFormFile,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BAlert,
  BCard,
  BCardText,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg,
} from 'bootstrap-vue';
import { ref, onUnmounted } from '@vue/composition-api';
import { required, alphaNum, validEmail } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import Ripple from 'vue-ripple-directive';
import { serialize } from 'object-to-formdata';
import vSelect from 'vue-select';
import flatPickr from 'vue-flatpickr-component';
import store from '@/store';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { Arabic } from 'flatpickr/dist/l10n/ar.js';
import { alertMessageMixin } from '@core/mixins/ui/feedback';
import { heightTransition } from '@core/mixins/ui/transition';
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils';
import accountStore from './accountStore';

export default {
  components: {
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      optionsLocal: JSON.parse(JSON.stringify(this.accountData)),
      licenseFile: null,
      signatureFile: null,
      resetPasswordData: {
        oldPassword: '',
        newPassword: '',
        confirmNewPassword: '',
      },
    };
  },
  methods: {

    updateAccount() {
    // Dispatch the updateAccount action to update the account data
      this.$store
        .dispatch('app-account/updateAccount', {
          accountData: this.accountData,
          id: this.currentUser.id,
        })
        .then((response) => {
        // Handle success
          this.$toast.success(response.data.message);
        })
        .catch((err) => {
        // Handle errors
          if (err.response) {
            if (err.response.status === 422) {
              this.$toast.error(err.response.data.message);
              const { message, errors } = err.response.data;
              if (errors) {
                const errorMessage = {
                  message: '',
                  type: 'danger',
                };
                Object.values(errors).forEach((error) => {
                  errorMessage.message += `${error[0]}<br>`;
                });
                this.displayMessage(errorMessage);
              }
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  text: message,
                  icon: 'AlertCircleIcon',
                  variant: 'danger',
                },
              });
            } else {
              this.$toast.error(err.response.data.error);
            }
          }
          console.error(err);
        });
    },
    // Update License Image
    updateLicenseImage() {
      if (this.licenseFile !== null) {
        const licenceData = serialize({ image: this.licenseFile });

        this.$store
          .dispatch('app-account/updateLicenseImage', { id: this.accountData.id, licenceData })
          .then((response) => {
            this.$toast.success(response.data.message);
            // Update the license image path in your local data or perform any other necessary action
          })
          .catch((err) => {
            if (err.response) {
              if (err.response.status === 422) {
                this.$toast.error(err.response.data.message);
                const { message, errors } = err.response.data;
                if (errors) {
                  const errorMessage = {
                    message: '',
                    type: 'danger',
                  };
                  Object.values(errors).forEach((error) => {
                    errorMessage.message += `${error[0]}<br>`;
                  });
                  this.displayMessage(errorMessage);
                }
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    text: message,
                    icon: 'AlertCircleIcon',
                    variant: 'danger',
                  },
                });
              } else {
                this.$toast.error(err.response.data.error);
              }
            }
            console.log(err);
          });
      }
    },

    // Update Signature Image
    updateSignatureImage() {
      if (this.signatureFile !== null) {
        const signatureData = serialize({ image: this.signatureFile });

        this.$store
          .dispatch('app-account/updateSignatureImage', { id: this.accountData.id, signatureData })
          .then((response) => {
            this.$toast.success(response.data.message);
          })
          .catch((err) => {
            if (err.response) {
              if (err.response.status === 422) {
                this.$toast.error(err.response.data.message);
                const { message, errors } = err.response.data;
                if (errors) {
                  const errorMessage = {
                    message: '',
                    type: 'danger',
                  };
                  Object.values(errors).forEach((error) => {
                    errorMessage.message += `${error[0]}<br>`;
                  });
                  this.displayMessage(errorMessage);
                }
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    text: message,
                    icon: 'AlertCircleIcon',
                    variant: 'danger',
                  },
                });
              } else {
                this.$toast.error(err.response.data.error);
              }
            }
            console.log(err);
          });
      }
    },
    resetPassword() {
    // Validate the reset password form

      // Dispatch the resetPassword action to reset the password
      this.$store
        .dispatch('app-account/resetPassword', {
          id: this.currentUser.id,
          resetPasswordData: this.resetPasswordData,
        })
        .then((response) => {
        // Handle success
          this.$toast.success(response.data.message);
          // Clear the password fields
          this.resetPasswordData.oldPassword = '';
          this.resetPasswordData.newPassword = '';
          this.resetPasswordData.confirmNewPassword = '';
        })
        .catch((err) => {
        // Handle errors
          if (err.response) {
            if (err.response.status === 422) {
              this.$toast.error(err.response.data.message);
              const { message, errors } = err.response.data;
              if (errors) {
                const errorMessage = {
                  message: '',
                  type: 'danger',
                };
                Object.values(errors).forEach((error) => {
                  errorMessage.message += `${error[0]}<br>`;
                });
                this.displayMessage(errorMessage);
              }
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  text: message,
                  icon: 'AlertCircleIcon',
                  variant: 'danger',
                },
              });
            } else {
              this.$toast.error(err.response.data.error);
            }
          }
          console.error(err);
        });
    },

  },
  setup() {
    const accountData = ref(null);
    const oldAccountData = ref(null);
    const ACCOUNT_APP_STORE_MODULE_NAME = 'app-account';
    const currentUser = JSON.parse(localStorage.getItem('userData'));
    console.log(currentUser);
    const resetAccountData = () => {
      if (oldAccountData.value !== null) {
        accountData.value = JSON.parse(JSON.stringify(oldAccountData.value));
      }
    };
    // Register module
    if (!store.hasModule(ACCOUNT_APP_STORE_MODULE_NAME)) {
      store.registerModule(ACCOUNT_APP_STORE_MODULE_NAME, accountStore);
    }
    // Unregister on leave
    onUnmounted(() => {
      if (store.hasModule(ACCOUNT_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(ACCOUNT_APP_STORE_MODULE_NAME);
      }
    });
    // Fetch account data
    store.dispatch('app-account/fetchAccount', { id: currentUser.id })
      .then((response) => {
        const { data: account } = response.data;
        accountData.value = account;
        oldAccountData.value = JSON.parse(JSON.stringify(account));
      })
      .catch((error) => {
        if (error.response.status === 404) {
          accountData.value = undefined;
        }
      });
    const signatureImageInput = ref(null);

    const signatureImagePreview = ref(null);

    const { inputImageRenderer: signatureImageRenderer } = useInputImageRenderer(signatureImageInput, signatureImagePreview);
    const licenseImageInput = ref(null);

    const licenseImagePreview = ref(null);

    const { inputImageRenderer: licenseImageRenderer } = useInputImageRenderer(licenseImageInput, licenseImagePreview);
    return {
      accountData,
      resetAccountData,
      signatureImageInput,
      licenseImageInput,
      signatureImagePreview,
      licenseImagePreview,
      signatureImageRenderer,
      licenseImageRenderer,
      currentUser,
    };
  },

};
</script>
