import axios from '@axios';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    // fetch accountData of user  by ID

    fetchAccount(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/user/${id}`) // Use the appropriate API endpoint for fetching a user by ID
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

    // Update a user account by ID with new accountData
    updateAccount(ctx, { accountData, id }) {
      return new Promise((resolve, reject) => {
        axios
          .put('/api/update-account', accountData) // Use the appropriate API endpoint for updating a user by ID
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

    // Reset the user's password
    resetPassword(ctx, { id, resetPasswordData }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/api/user/reset-password/${id}`, resetPasswordData)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

    // Update the user's signature
    updateSignatureImage(ctx, { id, signatureData }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/api/user/update-signature/${id}`, signatureData, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    // Update the user's licence
    updateLicenseImage(ctx, { id, licenceData }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/api/user/update-licence/${id}`, licenceData, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

  },
};
